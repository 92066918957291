<template>
  <div v-if="deviceLat != 0.0" class="card" id="mapBox">
    <GMapMap style="width: 100%;"
     :options="mapOptions" :zoom="12" :center="{
      lat: deviceLat,
      lng: deviceLong,
    }" map-type-id="satellite" @click="onMouseClick" ref="googleMap">
      <GMapMarker :key="slotName" :position="{
        lat: deviceLat,
        lng: deviceLong,
      }" :title="slotName" />
      <GMapMarker v-if="homeLat && homeLong" :position="{
        lat: homeLat,
        lng: homeLong,
      }" @click="openGpsHome()" :icon="{
      url: 'http://maps.google.com/mapfiles/kml/pushpin/grn-pushpin.png',
      scaledSize: {width: 40, height: 40}}"
        title="Home position" />
      <!-- Icons can be found at http://kml4earth.appspot.com/icons.html -->

      <b-button style="bottom: 5px; left: -12px;"  v-bind:style="{ backgroundColor: homeCursorActivated ? 'green' : 'transparent' }" @click="toggleHome()">Set home📌</b-button>
      <b-button style="bottom: 5px; left: -12px; background: transparent;" @click="toggleNDVI">{{ showNDVI ? 'Hide NDVI' : 'Show NDVI' }}</b-button>
    </GMapMap>
    <div id="gps" data-toggle="tooltip" data-placement="top" :title="gpsLastUpdate">
      <p style="display: flex; justify-content: space-between; margin-left: 100px;">
        <span class="text" style="color: white;">
          GPS: {{ deviceLat?.toFixed(5) }},&nbsp;{{ deviceLong?.toFixed(5) }}. Drift={{ getDrift() }} meters
        </span>
        <div class="gps-right" style="flex-shrink: 0;display: flex;align-items: center;justify-content: space-between;margin-right: 20px;">
          <svg v-if="fixOk" class="icon" style="width: 25px;height: 25px;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1884"><path d="M512.064 1024a512 512 0 1 1 512-512 512.576 512.576 0 0 1-512 512zM289.536 473.6a30.912 30.912 0 0 0-8.064 1.088 40.768 40.768 0 0 0-24.448 24.064 35.584 35.584 0 0 0 7.68 31.296l145.92 154.624a40.704 40.704 0 0 0 30.08 12.8 45.248 45.248 0 0 0 31.36-12.8l288.384-309.184 0.64-0.832a33.856 33.856 0 0 0-2.368-44.288 30.848 30.848 0 0 0-22.656-10.368 32 32 0 0 0-21.184 8.32L442.112 620.672 311.936 482.88a32 32 0 0 0-22.464-9.28z" fill="#2dcd70" p-id="1885"></path></svg>
          <svg v-else class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4923"><path d="M458.412715 512.477867l-164.4544 162.816a39.867733 39.867733 0 0 0-11.5712 27.511466 40.277333 40.277333 0 0 0 39.936 40.618667c10.990933 0.136533 21.6064-4.232533 29.354666-12.014933l164.1472-162.133334 163.7376 162.133334c7.4752 7.543467 17.681067 11.8784 28.330667 12.014933 10.410667-0.136533 20.343467-4.232533 27.784533-11.434667a39.799467 39.799467 0 0 0 0.546134-56.695466l-163.2256-162.474667 164.2496-162.133333a40.106667 40.106667 0 0 0 0-57.1392 41.096533 41.096533 0 0 0-57.685334 0l-163.874133 162.235733-164.0448-163.2256a41.096533 41.096533 0 0 0-57.685333 0 40.106667 40.106667 0 0 0 0 57.105067l164.4544 162.816zM515.620181 1024C229.992448 1023.112533-0.851285 793.258667 0.002048 510.429867c0.6144-204.526933 124.177067-389.12 314.197333-469.367467 265.659733-103.901867 566.0672 25.088 671.0272 288.085333 101.376 254.1568-16.657067 542.3104-268.219733 654.7456a518.587733 518.587733 0 0 1-201.386667 40.072534z" fill="#EC4956" p-id="4924"></path></svg>
          <span style="color: white;margin-left: 3px;">{{fixOk ? 'GPS Locked' : 'GPS Not Locked'}}</span>
        </div>
      </p>
    </div>
  </div>
  <div v-else>
    <span class="text"> No Data </span>
  </div>

  <b-modal v-model="gpsHomeModal" title="Select Home Position" @ok="confirmGpsHome()" @cancel="cancelGpsHome()"
    ok-title="Ok">
    <div>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Latitude:</p>
        </b-col>
        <b-col cols="8">
          <b-form-input class="exprt-form" type="number" v-model="homeLat" required>
          </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Longitude:</p>
        </b-col>
        <b-col cols="8">
          <b-form-input class="exprt-form" type="number" v-model="homeLong" required>
          </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Drift distance:</p>
        </b-col>
        <b-col cols="8">
          <label class="unitText">{{ getDrift() }} meters</label>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Alert distance (meters):</p>
        </b-col>
        <b-col cols="8">
          <b-form-input class="exprt-form" type="number" v-model="gpsHomeDistanceThreshold" required>
          </b-form-input>
        </b-col>
      </b-row>
    </div>
    <b-button class="mt-3" block @click="deleteHome">Delete Home Pin</b-button>
  </b-modal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useSlotStore } from "../stores/slotStore";
const homeLat = ref(0);
const homeLong = ref(0);
const gpsHomeDistanceThreshold = ref(0);
const homeCursorActivated = ref(false);

const showNDVI = ref(false);
const mapId = ref("");
const mapUrl = ref("");
// Reference to the Google Map component
const googleMap = ref(null);

const slotStore = useSlotStore();
let device = slotStore.currentSlot;
homeLat.value = device.gpsLatHome;
homeLong.value = device.gpsLongHome;
gpsHomeDistanceThreshold.value = device.gpsHomeDistanceThreshold;

const gpsHomeModal = ref(false);

const props = defineProps({
  deviceLat: Number,
  deviceLong: Number,
  gpsLastUpdate: String,
  mapOptions: Object,
  slotName: String,
  fixOk:Number
}
)

const onMouseClick = (e) => {
  if (homeCursorActivated.value === false) {
    return;
  }

  const latLng = e.latLng;
  homeLat.value = latLng.lat()?.toFixed(5);
  homeLong.value = latLng.lng()?.toFixed(5);
  gpsHomeModal.value = true;
  homeCursorActivated.value = false;
}

const toggleHome = () => {
  homeCursorActivated.value = !homeCursorActivated.value;
}

const openGpsHome = () => {
  homeLat.value = device.gpsLatHome?.toFixed(5);
  homeLong.value = device.gpsLongHome?.toFixed(5);
  gpsHomeDistanceThreshold.value = device.gpsHomeDistanceThreshold;
  gpsHomeModal.value = true;
}

const confirmGpsHome = async () => {
  const slotId = device._id;
  homeLat.value = parseFloat(homeLat.value);
  homeLong.value = parseFloat(homeLong.value); 
  
  homeCursorActivated.value = false;
  await slotStore.updateSlot(slotId, {
    gpsLatHome: homeLat.value,
    gpsLongHome: homeLong.value,
    gpsHomeDistanceThreshold: parseFloat(gpsHomeDistanceThreshold.value)
  });

  device = slotStore.currentSlot;
  gpsHomeModal.value = false;
}

const cancelGpsHome = async () => {
  homeLat.value = device.gpsLatHome?.toFixed(5);
  homeLong.value = device.gpsLongHome?.toFixed(5);
  gpsHomeDistanceThreshold.value = device.gpsHomeDistanceThreshold;
  gpsHomeModal.value = false;
  homeCursorActivated.value = false;
}

const deleteHome = async() => {
  homeLat.value = 0;
  homeLong.value = 0;
  homeCursorActivated.value = false;
  await confirmGpsHome();
}

function calculateDistance(lat1, lng1, lat2, lng2) {
  var R = 6371; // Radius of the earth in kilometers
  var dLat = toRadians(lat2 - lat1);
  var dLng = toRadians(lng2 - lng1);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
          Math.sin(dLng / 2) * Math.sin(dLng / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRadians(deg) {
  return deg * Math.PI / 180;
}

const getDrift = () => {
  if (homeLat.value && homeLong.value) {
    const distance = calculateDistance(props.deviceLat, props.deviceLong, homeLat.value, homeLong.value);
    return parseInt(distance * 1000);
  }
  else {
    return "";
  }
}

const overlayNDVI = (url) => {
  const map = googleMap.value.$mapObject; // Access the actual map instance
  const tileLayer = new google.maps.ImageMapType({
    getTileUrl: function(coord, zoom) {
      return url.replace("{z}", zoom).replace("{x}", coord.x).replace("{y}", coord.y);
    },
    tileSize: new google.maps.Size(256, 256),
    opacity: 0.7,
    name: "NDVI"
  });
  map.overlayMapTypes.push(tileLayer);
};

const removeNDVIOverlay = () => {
  const map = googleMap.value.$mapObject;
  map.overlayMapTypes.clear();
};

const toggleNDVI = async () => {
  showNDVI.value = !showNDVI.value;
  
  if (showNDVI.value) {
    // Fetch the NDVI Map ID from Google Earth Engine
    const response = await fetchNDVIData();
    mapId.value = response.mapid;
    mapUrl.value = response.urlFormat;
    overlayNDVI(mapUrl.value);
  } else {
    removeNDVIOverlay();
  }
};

const fetchNDVIData = async () => {
  // Replace with actual API call to Google Earth Engine
  // Here is a mock response for the sake of the example
  const ndviData = await slotStore.fetchNdvi(device._id);
  return ndviData;
};

</script>
<style scoped>
.vue-map-container {
  height: 92%;
}
</style>